body{
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.5;
  color: #24292e;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button, div {
  outline: none; 
}
.anticon svg {
  display: block;
}

.app {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type=text], input[type=email], input[type=password], textarea {
  border-radius: 8px;
  padding: 12px;
  border: 1px solid #DCE0E4;
}

input[type=text]:focus, input[type=email]:focus, input[type=password]:focus, textarea:focus {
  outline: none;
  box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px, 0px 0px 4px #0055d4;
}

button:focus {
  outline: none;
}

input.error {
  border-color: red;
}

.input-feedback {
  color: red;
  height: 5px;
  margin-top: -12px;
}
